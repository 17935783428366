<template>
  <div>
    <v-row>
      <v-col>
        <div>loading: {{state.isLoading}}</div>
        <div>success: {{state.isSuccess}}</div>
        <div>data: {{someData}}</div>
        <div>error: {{state.isError}}</div>
        <div>errorMessage: {{state.errorMessage}}</div>
      </v-col>
      <v-col>
        <div>loading: {{state1.isLoading}}</div>
        <div>success: {{state1.isSuccess}}</div>
        <div>data: {{someData1}}</div>
        <div>error: {{state1.isError}}</div>
        <div>errorMessage: {{state1.errorMessage}}</div>
      </v-col>
    </v-row>

    <v-btn
    class="my-5 mx-5"
    color="primary"
    elevation="2"
    @click="testPush()"
  >ТЕСТ PUSH</v-btn>

  </div>


</template>

<script>


import {
  Entity_ComBranch,
  Entity_ComDiv,
  Entity_Spec,
  Entity_User,
  Entity_UserSpec,
  Entity_VisitPlan
} from "../../../EntityStoreCacheService";
import State from "@/plugins/state";

import axios from "axios";

export default {
  data: () => ({
    state: new State(),
    state1: new State(),
    someData:null,
    someData1:null
  }),
  mounted(){
    this.state.stateLoading();

    let builder = new Entity_VisitPlan()
        .selects(Entity_VisitPlan.id)
        .selects(Entity_VisitPlan.begin)
        .selects(Entity_VisitPlan.isLiveList)
        .selects(Entity_VisitPlan.comment)


        .selects([Entity_VisitPlan.spec, Entity_Spec.id].join('.'))
        .selects([Entity_VisitPlan.spec, Entity_Spec.name].join('.'))

        .selects([Entity_VisitPlan.userSpec, Entity_UserSpec.id].join('.'))
        .selects([Entity_VisitPlan.userSpec, Entity_UserSpec.commentAdmin].join('.'))

        .selects([Entity_VisitPlan.userSpec, Entity_UserSpec.spec, Entity_Spec.id].join('.'))
        .selects([Entity_VisitPlan.userSpec, Entity_UserSpec.spec, Entity_Spec.name].join('.'))

        .selects([Entity_VisitPlan.userSpec, Entity_UserSpec.user, Entity_User.id].join('.'))
        .selects([Entity_VisitPlan.userSpec, Entity_UserSpec.user, Entity_User.firstName].join('.'))
        .selects([Entity_VisitPlan.userSpec, Entity_UserSpec.user, Entity_User.lastName].join('.'))
        .selects([Entity_VisitPlan.userSpec, Entity_UserSpec.user, Entity_User.secondName].join('.'))
        .selects([Entity_VisitPlan.userSpec, Entity_UserSpec.user, Entity_User.imageUrl].join('.'))

        .selects([Entity_VisitPlan.userSpec, Entity_UserSpec.comDiv, Entity_ComDiv.id].join('.'))
        .selects([Entity_VisitPlan.userSpec, Entity_UserSpec.comDiv, Entity_ComDiv.name].join('.'))
        .selects([Entity_VisitPlan.userSpec, Entity_UserSpec.comDiv, Entity_ComDiv.comBranch, Entity_ComBranch.id].join('.'))
        .selects([Entity_VisitPlan.userSpec, Entity_UserSpec.comDiv, Entity_ComDiv.comBranch, Entity_ComBranch.name].join('.'))

        .selects([Entity_VisitPlan.user, Entity_User.id].join('.'))
        .selects([Entity_VisitPlan.user, Entity_User.firstName].join('.'))
        .selects([Entity_VisitPlan.user, Entity_User.lastName].join('.'))
        .selects([Entity_VisitPlan.user, Entity_User.secondName].join('.'))
        .selects([Entity_VisitPlan.user, Entity_User.phone].join('.'))

    builder.filterAnd([Entity_VisitPlan.entity, Entity_VisitPlan.id].join('.')+' !=?',6898);

    builder.filterAnd([Entity_VisitPlan.entity, Entity_VisitPlan.user, Entity_User.id].join('.')+'=?',218);

    builder.filterAnd([Entity_VisitPlan.entity, Entity_VisitPlan.userSpec, Entity_UserSpec.user, Entity_User.id].join('.')+'=?',21);


    builder.filterAnd([Entity_VisitPlan.entity, Entity_VisitPlan.isLiveList].join('.')+'=?', 0);


    builder.page(1).onPage(3000);

    /*
    let builder = new Entity_User();
      //поля которые хотим посмотреть
      builder.selects(Entity_User.id)
          .selects(Entity_User.firstName)

          //виртуальные поля
          .virtual(Entity_User.virtual_paysCount, 1,2)

          //коллектор поля
          .collect(Entity_User.collect_paysCount)

          //сортировка
          .order(Entity_User.id, true)// по возрастанию
          .order(Entity_User.firstName, false)// добавляем второе поле для сортировки, по убыванию

          //пагинация не обязательно (по умолчанию: 1, 25)
          .page(1)
          .onPage(25)

          //фильтры
          .filterAnd('user.id>? and user.firstName LIKE ?', 1, '%Тест%')
          .filterAnd('user.userProfile.birth>?', moment().subtract().subtract(25, "years"))

          //фильтр по виртуальным полям (пишется индивидуально на бэке)
          //.filterAdd(['overDate'].join('.'));

          /*
          .filterAnd('user.id<10 or user.id=?1')
          .filterAnd('user.id in (?2)')
          .filterOr('user.id=1119907')
          .filterOr('user.firstName LIKE ?3')
          .filterOr('user.userProfile.birth>?4')
          //значения фильтров
          .filterParams(
              1119901,
              [1119900, 1119901, 1119902],//можно массивы
              '%Тест%',//like
              moment().subtract().subtract(25, "years")//можно просто момент
          );

           */


    builder.request((data)=>{
          this.someData = data;
          console.log(data);
          this.state.stateSuccess();
        }, (e)=>{
          console.error(e.response.data.message);
          this.state.stateError(e);
        })

    /*
    this.state1.stateLoading();
    new Entity_Visit()
        .selects(Entity_Visit.id)
        .selects(Entity_Visit.begin)
        .selects([Entity_Visit.leadUser,Entity_ComPlace.id].join('.'))
        .selects([Entity_ComPlace.entity,Entity_ComRoom.entity, Entity_ComRoom.sid].join('.'))
        // или как вариант ниже
        .order(Entity_Visit.begin, true)
        .page(1)
        .onPage(30)

        .filterAnd('visit.comPlace.comRoom.sid=?1')
        .filterAnd('visit.begin>=?2')
        .filterParams(
            'K11',
            moment().subtract().subtract(1, "months")//можно просто момент
        )
        .request((data)=>{
          this.someData1 = data;
          console.log(data);
          this.state1.stateSuccess();
        }, (e)=>{
          console.error(e.response.data.message);
          this.state1.stateError(e);
        })

     */
  },
  methods:{
    testPush(){
      axios.post('/serviceApi/testPush/', {
         message:'Тест',
        }, {withCredentials: true})
            .then(response => console.log(response))
            .catch(e =>  console.log(e));
    }
  }
}
</script>